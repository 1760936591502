var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"parent",staticClass:"field flex align-center",class:[{
    'is-focused': _vm.isFocus,
    'has-value': _vm.value,
    'has-error': _vm.errorHint,
    'is-disabled': _vm.isDisabled,
    'is-dark': _vm.dark,
    'no-label': _vm.noLabel
  }, _vm.inputSize],on:{"click":_vm.focusInput}},[_c('input',_vm._b({ref:"CustomInput",staticClass:"field-input",class:{ 'no-clear-button': _vm.noClearButton },style:([_vm.borderStyle]),attrs:{"id":_vm.$attrs.id,"placeholder":_vm.label,"type":"text","readonly":!_vm.enabledManualInput},domProps:{"value":_vm.value},on:{"keydown":function($event){return _vm.keyDownInput($event)},"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"click":function($event){return _vm.$emit('click')}}},'input',_vm.$attrs,false)),(!_vm.noLabel)?_c('label',{ref:"label",staticClass:"field-label",class:_vm.errorHint ? 'text-danger' : null,style:([_vm.colorStyle]),attrs:{"for":_vm.$attrs.id},on:{"click":_vm.focusInput}},[_vm._v("\n    "+_vm._s(_vm.hint || _vm.label)+"\n  ")]):_vm._e(),(_vm.hasClearButton)?_c('CustomButton',{staticClass:"field-clear-button",attrs:{"color":_vm.dark ? '#757575' : 'rgba(0, 0, 0, 0.54)',"dark":_vm.dark,"round":""},on:{"click":function($event){return _vm.$emit('clear')}}},[_c('span',{staticClass:"fs-16"},[_vm._v("\n      ✕\n    ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }