(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["@nzykov/vue-ctk-date-time-picker"] = factory();
	else
		root["@nzykov/vue-ctk-date-time-picker"] = factory();
})((typeof self !== 'undefined' ? self : this), function() {
return 